<template>
  <div>
    <div class="choose-infos">
      <div class="choose-item" v-for="item in chooseLabels" :key="item.value">
        <div>{{item.label}}</div>
      </div>
      <div class="choose-item">
        <fm-btn size="small" @click="openDialog = true">调整</fm-btn>
      </div>
    </div>
    <fm-modal
      :mask-closable="false"
      :value="openDialog"
      width="800"
      v-if="openDialog"
      theme="mh-withe"
      @cancel="openDialog = false">
      <div class="choose-infos">
        <div class="choose-item" style="margin-right: 30px;">所属组织架构/职务：</div>
        <div class="choose-item" v-for="item in chooseLabels" :key="item.value">
          <div>{{item.label}}</div>
          <a @click="del(item.value)" class="del-item" href="javascript:;">X</a>
        </div>
      </div>
      <div style="width:100%;max-height:65vh;overflow: auto;">
        <org-tree :is-select="true" @change="change"></org-tree>
      </div>
      <div class=btm-c>
        <fm-btn @click="openDialog = false">关闭</fm-btn>
      </div>
    </fm-modal>
  </div>
</template>

<script>
import OrgTree from '@/views/org/org/orgTree'

export default {
  name: 'orgRoleChoose',
  components: {
    OrgTree
  },
  data () {
    return {
      modalOrg: false,
      onError: false,
      valueEdit: '',
      openDialog: false
    }
  },
  watch: {
    value: {
      handler () {
        this.valueEdit = this.value ? this.value.map(v => v) : []
      },
      immediate: true
    }
  },
  props: {
    value: [Array]
  },
  model: {
    prop: 'value',
    event: 'change'
  },
  computed: {
    chooseLabels () {
      let allData = []
      let each = (datas) => {
        datas.forEach(v => {
          allData.push(Object.assign({}, v, {child: []}))
          each(v.child)
        })
      }
      each(this.$store.getters.orgTree)
      if (allData.length === 0) {
        return []
      }
      return this.valueEdit.map(v => {
        let orgId = Number(v.split('_')[0])
        let roleId = Number(v.split('_')[1])
        let org = allData.find(v1 => v1.id === orgId)
        let roleName = (org.roleList.find(v1 => v1.id === roleId) || {'name': '无职务'}).name
        return {
          value: v,
          label: org.name + '/' + roleName
        }
      })
    }
  },
  methods: {
    del (item) {
      this.valueEdit = this.valueEdit.filter(v => v !== item)
      this.$emit('change', this.valueEdit)
    },
    change (data) {
      if (this.valueEdit.includes(data.value)) {
        this.valueEdit = this.valueEdit.filter(v => v !== data.value)
      } else {
        this.valueEdit.push(data.value)
      }
      this.$emit('change', this.valueEdit)
    }
  }
}
</script>

<style lang="less" scope>
.choose-infos {
  display: flex;
  flex-wrap: wrap;
  .choose-item {
    margin-right: 30px;
    display: flex;
    align-items: center;
    height: 40px;
    .del-item {
      margin-left: 10px;
      width: 20px;
      height: 20px;
      line-height: 20px;
      border: 1px solid #F4628F;
      color: #F4628F;
      text-align: center;
      font-weight: 600;
      border-radius: 100%;
    }
  }
}
.btm-c {
  display: flex;
  justify-content: center;
}
.org-form {
  min-width: 50px;
  width: 100%;
  border-radius: 4px;
  transition: all 0.2s;
  display: inline-flex;
  box-sizing: border-box;
  padding: 0;
  border: 1px solid #dcdee2;
  align-items: center;
  box-shadow: 0 0 0px 3px transparent;
  font-size: 14px;
  color: #515a6e;
  padding: 0 8px;
  min-height: 30px;
}
.org-form-dev {
  // 报错设置
  .verifier-error {
    border-color: red;
  }
}
</style>