<template>
  <div class="user-form" v-loading="loading">
      <fm-title :title-text="userData.id ? '用户修改' : '用户新增'">
      </fm-title>
        <fm-form style="padding: 0 50px;" label-width="150px">
          <fm-form-item label="姓名">
            <fm-input-new v-model="userData.name" required v-verifier placeholder="姓名"/>
          </fm-form-item>
          <!-- <fm-form-item label="用户名">
            <fm-input-new v-model="userData.nickname" required v-verifier placeholder="用户名"/>
          </fm-form-item> -->
          <fm-form-item label="账号">
            <fm-input-new v-model="userData.account" :disabled="Boolean(userData && userData.id)" required v-verifier placeholder="账号"/>
          </fm-form-item>
          <fm-form-item label="状态">
            <fm-select v-model="userData.status" required v-verifier block>
              <fm-option v-for="item in userStatusList" :key="item.key" :value="item.key" :label="item.label"></fm-option>
            </fm-select>
          </fm-form-item>
          <fm-form-item label="电话号码">
            <fm-input-new v-model="userData.phone" v-verifier placeholder="电话号码"/>
          </fm-form-item>
          <fm-form-item label="身份证号">
            <fm-input-new v-model="userData.idNo" v-verifier placeholder="身份证号"/>
          </fm-form-item>
          <fm-form-item label="角色权限">
            <fm-select v-model="userData.roleIds" block filterable multiple>
              <fm-option v-for="item in roleList" :key="item.key" :value="item.key" :label="item.label"></fm-option>
            </fm-select>
          </fm-form-item>
          <fm-form-item label="所属组织架构/职务">
            <OrgRoleChoose :is-select="true" v-model="userData.orgRoleValues"></OrgRoleChoose>
          </fm-form-item>
          <!-- <fm-form-item label="是否为人事员工" v-if="!userData.id">
            <FmSwitch v-model="userData.isWorker"></FmSwitch>
          </fm-form-item> -->
          <fm-form-item label="排序值">
            <fm-input-number v-model="userData.sortNum" v-verifier placeholder="排序值"/>
          </fm-form-item>
        </fm-form>
        <div class="btm-c">
        <fm-btn @click="save" style="margin-right: 3rem;">保存</fm-btn>
        <fm-btn @click="$emit('close')">取消</fm-btn>
      </div>
  </div>
</template>

<script>
import OrgRoleChoose from './orgRoleChoose'

import {
  userRequest
} from '@/api'

import {
  updateUserDeal
} from '@/sys'

export default {
  components: {
    OrgRoleChoose
  },
  props: {
    user: { type: Object },
    roleList: { type: Array }
  },
  watch: {
    user: {
      handler () {
        this.initUser()
      },
      immediate: true,
      deep: true
    }
  },
  data () {
    return {
      openDialog: false,
      orgRoleValues: [],
      loading: false,
      userData: {
        name: '',
        isWorker: false,
        nickname: '',
        account: null,
        phone: null,
        idNo: null,
        roleIds: [],
        orgRoles: [],
        orgRoleValues: [],
        sortNum: null,
        status: 'active'
      }
    }
  },
  computed: {
    userStatusList () {
      return this.$store.getters.userStatusList
    }
  },
  methods: {
    async save () {
      if (!this.userData.name) {
        this.$notice.success({
          title: '系统提示',
          desc: '请输入姓名'
        })
        return
      }
      if (!this.userData.account) {
        this.$notice.success({
          title: '系统提示',
          desc: '请输入账号'
        })
        return
      }
      if (!this.userData.status) {
        this.$notice.success({
          title: '系统提示',
          desc: '请选择状态'
        })
        return
      }
      this.loading = true
      this.userData.orgRoleList = this.userData.orgRoleValues.map(v => {
        return {
          orgId: Number(v.split('_')[0]),
          roleId: Number(v.split('_')[1]) === 0 ? null : Number(v.split('_')[1])
        }
      })
      if (this.userData.id) {
        // let data = await updateUserDeal(this, this.userData)
        // if (data) {
        //   if (data === 'done') {
        //     await userRequest.updateAuth(this.userData.id, this.userData)
        //   }
        // } else {
        //   await userRequest.update(this.userData.id, this.userData)
        // }
        await updateUserDeal(this, this.userData)
        await userRequest.update(this.userData.id, this.userData)
      } else {
        await userRequest.add(this.userData)
      }
      this.$emit('reload')
      this.$emit('close')
      this.loading = false
    },
    initUser () {
      this.userData = {
        id: this.user.id || null,
        name: this.user.name || '',
        nickname: this.user.nickname || '',
        account: this.user.account || null,
        phone: this.user.phone || null,
        idNo: this.user.idNo || null,
        roleIds: (this.user.orgRoles || []).filter(v => v.orgId === null && v.roleId !== null).map(v => v.roleId),
        orgRoles: (this.user.orgRoles || []).filter(v => v.orgId !== null),
        orgRoleValues: (this.user.orgRoles || []).filter(v => v.orgId !== null).map(v => v.orgId + '_' + (v.roleId || 0)),
        sortNum: this.user.sortNum || null,
        status: this.user.status || null
      }
    }
  }
}
</script>

<style lang="less" scoped>
.user-form {
  width: 100%;
  .org-role-item {
    display: flex;
    padding: 8px 0;
    .org {
      margin-right: 2rem;
    }
  }
  .btm-c {
    padding-top: 20px;
  }
}
</style>